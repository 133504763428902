import React, {useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Drawer, Form, Input, message, Modal, Tree} from 'antd';
import {LockOutlined} from '@ant-design/icons';

const NoteList = ({notes, onSelectNote, handleEditNote}) => {
    const [visible, setVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedNote, setSelectedNote] = useState(null);
    const [passwordModalVisible, setPasswordModalVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [savePassword, setSavePassword] = useState(false);
    const [draggingNode, setDraggingNode] = useState(null);
    const [dragOverNodeKey, setDragOverNodeKey] = useState(null);
    const inputRef = useRef(null);

    const showDrawer = () => {
        setVisible(true);
    };

    const closeDrawer = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 50);
        }
    }, [visible]);

    const handleSearchChange = (e) => {
        setSearchText(e.target.value.toLowerCase());
    };

    const handleSelectNote = (note) => {
        if (!note) return;

        const savedGlobalPassword = localStorage.getItem('globalPassword');

        if (note.recommend === -1) {
            setSelectedNote(note);

            if (savedGlobalPassword) {
                // 如果全局密码已保存，直接使用全局密码打开笔记
                onSelectNote(note.id, savedGlobalPassword);
                closeDrawer();
            } else {
                // 没有保存全局密码，显示密码输入框
                setPasswordModalVisible(true);
            }
        } else {
            onSelectNote(note.id, 'x');
            closeDrawer();
        }
    };

    const handlePasswordOk = async () => {
        if (password && selectedNote) {
            try {
                await onSelectNote(selectedNote.id, password);
                if (savePassword) {
                    // 将密码保存为全局密码到localStorage
                    localStorage.setItem('globalPassword', password);
                }
                setPasswordModalVisible(false);
                closeDrawer();
            } catch (error) {
                message.error(error.response.data.message || 'Failed to get the note');
            }
        } else {
            message.warning('Please enter the password');
        }
    };

    const handlePasswordCancel = () => {
        setPasswordModalVisible(false);
    };

    const filteredNotes = notes.filter(note => note?.title?.toLowerCase().includes(searchText));

    const formatTreeData = (nodes) => {
        const nodeMap = new Map();
        const rootNodes = [];

        nodes.forEach(node => {
            nodeMap.set(node.id, {...node, key: node.id, title: node.title, children: []});
        });

        nodeMap.forEach(node => {
            if (node.parent === -1) {
                rootNodes.push(node);
            } else {
                const parentNode = nodeMap.get(node.parent);
                if (parentNode) {
                    parentNode.children.push(node);
                } else {
                    // 父节点找不到，将其平铺在根节点下
                    rootNodes.push(node);
                }
            }
        });

        return rootNodes;
    };

    const treeData = formatTreeData(filteredNotes);

    const handleTreeSelect = (selectedKeys) => {
        const selectedKey = selectedKeys[0];
        const selectedNote = notes.find(note => note.id === selectedKey);
        handleSelectNote(selectedNote);
    };

    const handleDragStart = (event) => {
        const draggingNode = notes.find(note => note.id === event.node.key);
        setDraggingNode(draggingNode);
    };

    const handleDragEnter = (info) => {
        setDragOverNodeKey(info.node.key); // 记录当前拖拽到的节点
    };

    const handleDrop = async ({node, dragNode, dropPosition}) => {
        if (!draggingNode) return;

        const newParent = dropPosition === -1 ? '-1' : node.key;

        const updatedNote = {
            id: draggingNode.id,
            parent: newParent
        };

        await handleEditNote(updatedNote);

        setDragOverNodeKey(null); // 清除拖拽状态
        setDraggingNode(null); // 清除拖拽节点
    };

    const renderTitle = (note) => (
        <span>
            {note.recommend === -1 && <LockOutlined style={{marginRight: '8px'}}/>}
            {note.title}
        </span>
    );

    return (
        <>
            <Button type="primary" onClick={showDrawer}>
                List
            </Button>
            <Drawer
                title="Note List"
                placement="left"
                onClose={closeDrawer}
                visible={visible}
                width={400}
            >
                <Input
                    ref={inputRef}
                    placeholder="Search by title"
                    value={searchText}
                    onChange={handleSearchChange}
                    style={{marginBottom: '16px'}}
                />
                <Tree
                    treeData={treeData}
                    titleRender={renderTitle}
                    draggable
                    dropToGap
                    onSelect={handleTreeSelect}
                    onDrop={handleDrop}
                    onDragStart={handleDragStart}
                    onDragEnter={handleDragEnter}
                />
            </Drawer>

            <Modal
                title="Enter Password"
                visible={passwordModalVisible}
                onOk={handlePasswordOk}
                onCancel={handlePasswordCancel}
                okText="Submit"
                cancelText="Cancel"
            >
                <Form
                    layout="vertical"
                    onFinish={handlePasswordOk}
                >
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{required: true, message: 'Please enter the password'}]}
                    >
                        <Input.Password
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Checkbox
                            checked={savePassword}
                            onChange={(e) => setSavePassword(e.target.checked)}
                        >
                            Save
                        </Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default NoteList;