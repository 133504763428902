import React, {useEffect, useState} from 'react';
import {Button, Input, Layout, message, Modal} from 'antd';
import NoteList from './component/NoteList';
import NoteDetail from './component/NoteDetail';
import NoteForm from './component/NoteForm';
import {addNote, deleteNote, editNote, getNoteDetails, getNotes} from './api';
import './App.css'

const {Header, Sider, Content} = Layout;

const App = () => {
    const [notes, setNotes] = useState([]);
    const [selectedNote, setSelectedNote] = useState(null);
    const [selectedParent, setSelectedParent] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deletePassword, setDeletePassword] = useState('');

    useEffect(() => {
        fetchNotes();
    }, []);

    const fetchNotes = async () => {
        const response = await getNotes();
        setNotes(response.data);
    };

    const handleSelectNote = async (id, password) => {
        try {
            const response = await getNoteDetails(id, password);
            setSelectedNote(response.data);
            setIsEditMode(false);
        } catch (error) {
            message.error(error.response.data.message || 'Failed to get the note detail');
            if (error.response.data.message === "授权码错误！！") {
                localStorage.removeItem('globalPassword');
            }
        }
    };

    const handleAddNote = async (note) => {
        const response = await addNote(note);
        fetchNotes();
        setSelectedNote(response.data);
        setIsEditMode(false);
    };

    const handleEditNote = async (note) => {
        try {
            await editNote(note);
            fetchNotes();
            setSelectedNote(note);
            setIsEditMode(false);
        } catch (error) {
            message.error(error.response.data.message || 'Failed to edit the note');
        }
    };

    const handleDeleteNote = async () => {
        if (deletePassword) {
            try {
                await deleteNote(selectedNote?.id, deletePassword);
                fetchNotes();
                setSelectedNote(null);
                message.success('Note deleted successfully');
            } catch (error) {
                message.error(error.response.data.message || 'Failed to delete the note');
            } finally {
                setIsDeleteModalVisible(false);
                setDeletePassword('');
            }
        } else {
            message.warning('Please enter the password');
        }
    };

    const handleOpenEditForm = () => {
        setIsEditMode(true);
    };

    const handleOpenAddForm = () => {
        setSelectedParent(selectedNote ? selectedNote.id : null);
        setSelectedNote(null);
        setTimeout(() => setIsEditMode(true));
    };

    const showDeleteModal = () => {
        setIsDeleteModalVisible(true);
    };

    return (
        <Layout style={{height: '100vh'}}>
            <Layout>
                <Header className="site-layout-background" style={{padding: 0, backgroundColor: '#001529'}}>
                    <NoteList notes={notes} onSelectNote={handleSelectNote} handleEditNote={handleEditNote}/>
                    <Button type="primary" disabled={isEditMode} onClick={handleOpenAddForm} style={{margin: 16}}>
                        Add
                    </Button>
                    <Button type="primary" disabled={!selectedNote || isEditMode} onClick={handleOpenEditForm}>
                        Edit
                    </Button>
                    <Button type="primary" style={{margin: 16}} disabled={!selectedNote || isEditMode} onClick={showDeleteModal}>
                        Delete
                    </Button>
                    <Button type="primary" disabled={selectedNote === null || isEditMode} onClick={() => setSelectedNote(null)}>
                        Reset
                    </Button>
                </Header>
                <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
                    {isEditMode ? (
                        <NoteForm
                            onSubmit={selectedNote ? handleEditNote : handleAddNote}
                            initialData={selectedNote ? selectedNote : {parent: selectedParent}}
                            onCancel={() => setIsEditMode(false)}
                        />
                    ) : (
                        <NoteDetail note={selectedNote}/>
                    )}
                </Content>

                <Modal
                    title="Delete Note"
                    visible={isDeleteModalVisible}
                    onOk={handleDeleteNote}
                    onCancel={() => setIsDeleteModalVisible(false)}
                >
                    <p>Enter password to delete the note:</p>
                    <Input.Password
                        value={deletePassword}
                        onChange={(e) => setDeletePassword(e.target.value)}
                    />
                </Modal>
            </Layout>
        </Layout>
    );
};

export default App;