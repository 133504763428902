import React, {useEffect, useRef, useState} from 'react';
import {FloatButton, Modal, Button, Image} from 'antd';
import html2canvas from 'html2canvas';

const NoteDetail = ({note}) => {
    const executedNoteIdRef = useRef(null); // 用于跟踪已经执行过的 note.id
    const [image, setImage] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        // 检查 note.id 是否已经执行过
        if (note?.recommend === -1 && note?.id && executedNoteIdRef.current !== note.id) {
            const scripts = document.getElementById("noteDetail").querySelectorAll('script');

            scripts.forEach((script) => {
                const newScript = document.createElement('script');
                newScript.textContent = script.textContent;

                document.body.appendChild(newScript);
                document.body.removeChild(newScript);
            });

            // 记录当前已执行的 note.id
            executedNoteIdRef.current = note.id;
        }
    }, [note]);

    const captureAndShowImage = () => {
        const noteDetailElement = document.getElementById('noteDetail');
        html2canvas(noteDetailElement).then(canvas => {
            const imageUrl = canvas.toDataURL('image/png');
            setImage(imageUrl);
            setIsModalVisible(true);
        });
    };

    return (
        <div>
            <div id="noteDetail">
                <h2
                    style={{
                        padding: '8px 0',
                        textAlign: 'center',
                        fontSize: '24px',
                        margin: 0,
                        background: '#fff',
                    }}
                >
                    {note?.title || ''}
                </h2>

                <div
                    style={{
                        padding: '24px 0',
                        background: '#fff',
                        minHeight: 500,
                    }}
                    dangerouslySetInnerHTML={{__html: note?.content || 'Please select a note to view the details'}}
                />

                <FloatButton.BackTop style={{bottom: 20, right: 20}}/>


                {/* Modal to display the image */}
                <Modal
                    visible={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}
                >
                    {image && <Image src={image} alt="Captured Note" style={{width: '100%'}}/>}
                </Modal>
            </div>

            {note &&
                <Button
                    onClick={captureAndShowImage}
                    style={{position: 'fixed', bottom: 20, right: 80}}
                    type="primary"
                >
                    Capture
                </Button>
            }
        </div>

    );
};

export default NoteDetail;