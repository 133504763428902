import axios from 'axios';

const BASE_URL = 'https://bitcoinrobot.cn/api/note';

export const getNotes = () => axios.get(BASE_URL);
export const getNoteDetails = (id, password) => {
    return axios.post(`${BASE_URL}/get/${id}`, password, {
        headers: {
            'Content-Type': 'text/plain'
        }
    });
};
export const addNote = (note) => axios.post(`${BASE_URL}/add`, note);
export const editNote = (note) => axios.post(`${BASE_URL}/edit`, note);
export const deleteNote = (id, password) => {
    return axios.post(`${BASE_URL}/delete/${id}`, password, {
        headers: {
            'Content-Type': 'text/plain'
        }
    });
};